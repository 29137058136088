import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import RightArrow from "../assets/icons/chevron-right.svg";
import CloseButton from "../assets/icons/x.svg";
import Contact from "../components/contact";
import Header from "../components/Header";
import Layout from "../components/layout";
import styles from "../styles/about.module.scss";

// function shuffleArray(array) {
//   let i = array.length - 1;
//   for (; i > 0; i--) {
//     const j = Math.floor(Math.random() * (i + 1));
//     const temp = array[i];
//     array[i] = array[j];
//     array[j] = temp;
//   }
//   return array;
// }

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      media: allFile(
        filter: { relativeDirectory: { eq: "media" } }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            publicURL
          }
        }
      }

      people: allFile(
        filter: { relativeDirectory: { eq: "media/people" } }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            id
            name
            publicURL
          }
        }
      }

      info: allMembersinfoCsv {
        edges {
          node {
            name
            nickname
            title
            school
            course
            latin_honors
            special_awards
            tools
            writeup
          }
        }
      }

      techIcons: allFile(
        filter: { relativeDirectory: { eq: "assets/icons/tech" } }
      ) {
        edges {
          node {
            name
            publicURL
          }
        }
      }

      rightArrow: allFile(
        filter: { relativeDirectory: { eq: "assets/icons" } }
      ) {
        edges {
          node {
            name
            publicURL
          }
        }
      }
    }
  `);

  // for dividing people to different sections
  let people = data.people.edges.slice();

  let dividedPeople = [];
  while (people.length) {
    dividedPeople.push(people.splice(0, 4));
  }
  // dividedPeople = shuffleArray(dividedPeople);
  while (dividedPeople.length) {
    people.push(dividedPeople.splice(0, 2));
  }

  const getNickname = (name) => {
    const info = data.info.edges.find(
      (i) => i.node.name.toLowerCase() === name.toLowerCase()
    );
    if (info) return info.node.nickname;
    return name.split(" ", 1)[0];
  };

  const getPosition = (name) => {
    const info = data.info.edges.find(
      (i) => i.node.name.toLowerCase() === name.toLowerCase()
    );
    if (info) return info.node.title;
    return " ";
  };

  // for opening modals
  const handleOpenMemberModal = (element, id) => {
    profilePhotoHoverOut(document.getElementById(id));
    let info = data.info.edges;
    if (element) {
      const modalImgSrc = element.src;
      const name = element.alt;

      console.log(element);

      let image = document.getElementsByClassName(styles.personImage)[0]
        .children;
      image = Array.from(image);
      image[0].src = modalImgSrc;

      for (let i = 0; i < info.length; i++) {
        if (name.toLowerCase() === info[i].node.name.toLowerCase()) {
          document.getElementById("name").textContent = info[i].node.name;
          document.getElementById("title").textContent = info[i].node.title;

          let school = info[i].node.school;
          let course = info[i].node.course;

          if (school.indexOf(";") < 0) {
            document.getElementById("school").innerHTML = `
          ${info[i].node.school ? `${info[i].node.school}<br />` : ""}
          ${info[i].node.course ? `${info[i].node.course}<br />` : ""}
          <i>${
            info[i].node.latin_honors
              ? `${info[i].node.latin_honors}<br />`
              : ""
          }</i>
          <i>${
            info[i].node.special_awards ? info[i].node.special_awards : ""
          }</i>
        `;
          } else {
            school = school.split(";");
            course = course.split(";");
            document.getElementById("school").innerHTML = `
          ${info[i].node.school ? `${school[0]}<br />` : ""}
          ${info[i].node.course ? `${course[0]}<br />` : ""}
          <i>${
            info[i].node.latin_honors
              ? `${info[i].node.latin_honors}<br />`
              : ""
          }</i>
          <i>${
            info[i].node.special_awards ? info[i].node.special_awards : ""
          }</i>
          ${info[i].node.school ? `${school[1]}<br />` : ""}
          ${info[i].node.course ? `${course[1]}<br />` : ""}
        `;
          }

          const toolsIcons = toolsPerPerson(
            info[i].node.tools,
            data.techIcons.edges
          );

          if (toolsIcons) {
            if (toolsIcons.length <= 1) {
              document.getElementsByClassName(
                styles.nextToolsArrow
              )[0].style.display = "none";
            } else {
              document.getElementsByClassName(
                styles.nextToolsArrow
              )[0].style.display = "inline-flex";
            }
          }

          let htmlTools = "";

          for (let i = 0; i < toolsIcons.length; i++) {
            htmlTools += `<span class="${styles.toolsList} ${
              i === 0 ? styles.show : ""
            }">`;
            for (let j = 0; j < toolsIcons[i].length; j++) {
              htmlTools += `<img src="${toolsIcons[i][j].node.publicURL}" alt="${toolsIcons[i][j].node.name}"  title="${toolsIcons[i][j].node.name}" class="${styles.tool}" />`;
            }
            htmlTools += `</span>`;
          }

          document.getElementById("tools").innerHTML = htmlTools;

          document.getElementById("writeup").innerHTML = info[i].node.writeup;

          break;
        }
      }

      const modal = document.getElementsByClassName(styles.modals);
      setTimeout(() => {
        modal[0].classList.add(styles.showModal);
        modal[0].classList.add(styles.frontModal);
      }, 300);
    } else {
      console.log("nothing");
    }
  };

  const nextIcons = () => {
    let iconsSet = document.getElementsByClassName(styles.toolsList);
    iconsSet = Array.from(iconsSet);

    for (let i = 0; i < iconsSet.length; i++) {
      if (iconsSet[i].classList.contains(styles.show)) {
        iconsSet[i].classList.toggle(styles.show);

        if (i === iconsSet.length - 1) {
          iconsSet[0].classList.toggle(styles.show);
        } else {
          iconsSet[i + 1].classList.toggle(styles.show);
        }

        break;
      }
    }
  };

  const toolsPerPerson = (toolsNotParsed, availableIcons) => {
    const toolsIcons = [];
    const paginatedtoolsIcons = [];
    const tools = toolsNotParsed.split(",").map((item) => {
      return item.trim();
    });

    for (let i = 0; i < tools.length; i++) {
      for (let j = 0; j < availableIcons.length; j++) {
        if (
          tools[i].toLowerCase() === availableIcons[j].node.name.toLowerCase()
        ) {
          toolsIcons.push(availableIcons[j]);
          break;
        }
      }
    }

    while (toolsIcons.length) {
      paginatedtoolsIcons.push(toolsIcons.splice(0, 4));
    }

    return paginatedtoolsIcons;
  };

  const modalClick = (event) => {
    for (let i = 0; i < event.target.classList.length; i++) {
      if (event.target.classList[i] === styles.frontModal) {
        handleCloseMemberModal();
        break;
      }
    }
  };

  // for closing modals
  const handleCloseMemberModal = () => {
    const modal = document.getElementsByClassName(styles.modals);
    modal[0].classList.remove(styles.showModal);
    ready(); // to enable scrolling again
    setTimeout(() => {
      modal[0].classList.remove(styles.frontModal);
    }, 300);
  };

  // for browsing people
  let peopleSet = [];
  const nextPeople = () => {
    if (peopleSet.length === 0) {
      peopleSet = document.getElementsByClassName(styles.peopleList);
      peopleSet = Array.from(peopleSet);
    }

    for (let i = 0; i < peopleSet.length; i++) {
      if (peopleSet[i].classList.contains(styles.show)) {
        peopleSet[i].classList.toggle(styles.show);

        if (i === peopleSet.length - 1) {
          peopleSet[0].classList.toggle(styles.show);
        } else {
          peopleSet[i + 1].classList.toggle(styles.show);
        }

        break;
      }
    }
  };
  const prevPeople = () => {
    if (peopleSet.length === 0) {
      peopleSet = document.getElementsByClassName(styles.peopleList);
      peopleSet = Array.from(peopleSet);
    }

    for (let i = 0; i < peopleSet.length; i++) {
      if (peopleSet[i].classList.contains(styles.show)) {
        peopleSet[i].classList.toggle(styles.show);

        if (i === 0) {
          peopleSet[peopleSet.length - 1].classList.toggle(styles.show);
        } else {
          peopleSet[i - 1].classList.toggle(styles.show);
        }

        break;
      }
    }
  };

  const profilePhotoHover = (element) => {
    // event.target.classList.add(styles.imgOverlayShow);
    element.style["z-index"] = 0;
    element.classList.add(styles.imgOverlayShow);
  };

  const profilePhotoHoverOut = (element) => {
    // event.target.classList.remove(styles.imgOverlayShow);
    element.classList.remove(styles.imgOverlayShow);

    setTimeout(() => {
      // event.target.style["z-index"] = -1;
      element.style["z-index"] = -1;
    }, 1000);
  };

  const paginatedPeople = people.map((page, i) => {
    return (
      <Row
        key={`row_${i}`}
        xs={2}
        md={2}
        lg={4}
        className={`${styles.peopleList} ${styles.space} ${
          i === 0 ? styles.show : ""
        }`}
      >
        {page.map((row, j) => {
          return row.map((person, k) => {
            return (
              <Col key={`col_${i}${j}${k}`} md lg className={styles.colHeight}>
                <div key={`div_${i}${j}${k}`} className={`${styles.person}`}>
                  <div
                    role="button"
                    tabIndex={0}
                    key={`divdetails_${i}${j}${k}`}
                    id={`divdetails_${i}${j}${k}`}
                    className={styles.imgOverlay}
                    onMouseOver={() =>
                      profilePhotoHover(
                        document.getElementById(`divdetails_${i}${j}${k}`)
                      )
                    }
                    onMouseOut={() =>
                      profilePhotoHoverOut(
                        document.getElementById(`divdetails_${i}${j}${k}`)
                      )
                    }
                    onBlur={() => {}}
                    onFocus={() => {}}
                    onClick={() =>
                      handleOpenMemberModal(
                        document.getElementById(`img_${i}${j}${k}`),
                        `divdetails_${i}${j}${k}`
                      )
                    }
                    onKeyDown={() => {}}
                  >
                    <div className={styles.hoverDetails}>
                      <span className={styles.nameOverlay}>
                        {getNickname(person.node.name)}
                      </span>
                      <br />
                      <span className={styles.positionOverlay}>
                        {getPosition(person.node.name)}
                      </span>
                    </div>
                  </div>
                  <div
                    role="button"
                    tabIndex={0}
                    onMouseOver={() =>
                      profilePhotoHover(
                        document.getElementById(`divdetails_${i}${j}${k}`)
                      )
                    }
                    onFocus={() => {}}
                  >
                    <img
                      id={`img_${i}${j}${k}`}
                      key={`img_${i}${j}${k}`}
                      src={person.node.publicURL}
                      alt={person.node.name}
                    />
                  </div>
                  <span className={styles.nickname}>
                    {getNickname(person.node.name)}
                  </span>
                </div>
              </Col>
            );
          });
        })}
      </Row>
    );
  });

  // to focus the div element containing the contents
  const ready = () => {
    document.getElementsByClassName(styles.about)[0].focus();
  };

  useEffect(() => {
    if (document.readyState === "loading") {
      document.addEventListener("DOMContentLoaded", ready);
    } else {
      ready();
    }
  }, []);

  return (
    <Layout
      title="About | Mahusai"
      description="In 2010, a small group of people came together to start a modest software development company."
      pathname="/about/"
    >
      <div
        className={styles.about}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
      >
        {/* <NavMenu />
        <Link to="/">
          <Logo id={styles.smallLogo} />
        </Link> */}
        <Header />

        <Container fluid>
          <Row xs={1} className={styles.row}>
            <Col xs md lg={{ span: 7 }} className="col-height-mobile">
              <div className="details-section center-middle-position">
                <div className="title">Our Story</div>
              </div>
            </Col>
            <Col
              lg={{ span: 5 }}
              className={`${styles.bgBdazzledBlue} col-height-mobile`}
            >
              <div className="details-section center-middle-position">
                <div
                  className={`${styles.humbleTitle} title title-medium-size`}
                >
                  Humble beginnings
                </div>
                <div className="description">
                  <p>
                    In 2010, a small group of people came together to start a
                    modest software development company.
                  </p>
                  <p>
                    A decade later, that company has since grown into a
                    collection of highly skilled individuals who craft amazing
                    apps, websites, games, and more.
                  </p>
                </div>
              </div>
            </Col>
          </Row>

          <Row xs={1} className={`${styles.row} ${styles.rowWideScreens}`}>
            <Col
              lg={{ span: 7 }}
              className={`${styles.bgBdazzledBlue} ${styles.col} ${styles.firstSecondImgMobile}`}
            >
              <div className={styles.firstSecondImg}>
                <img
                  src={data.media.edges[1].node.publicURL}
                  alt={data.media.edges[1].node.name}
                  className={styles.firstImg}
                />
                <img
                  src={data.media.edges[2].node.publicURL}
                  alt={data.media.edges[2].node.name}
                  className={styles.secondImg}
                />
              </div>
            </Col>
            <Col lg={{ span: 5 }} className={`${styles.removePaddingRight}`}>
              <div className={styles.thirdfourthImg}>
                <img
                  src={data.media.edges[3].node.publicURL}
                  alt={data.media.edges[3].node.name}
                  className={styles.thirdImg}
                />
                <img
                  src={data.media.edges[4].node.publicURL}
                  alt={data.media.edges[4].node.name}
                  className={styles.fourthImg}
                />
                <div className={`${styles.description1}`}>
                  <h3>Work hard, play hard.</h3>
                  <p>
                    We like spending our time relaxing and playing games as much
                    as we like solving complex problems and building the best
                    software there is.
                  </p>
                </div>
              </div>
            </Col>
          </Row>

          <Row xs={1} className={`${styles.row} ${styles.rowWideScreens}`}>
            <Col
              lg={{ span: 7 }}
              className={`${styles.col} ${styles.fifthSixthImgMobile}`}
            >
              <div className={styles.fifthSixthImg}>
                <img
                  src={data.media.edges[5].node.publicURL}
                  alt={data.media.edges[5].node.name}
                  className={styles.fifthImg}
                />
                <img
                  src={data.media.edges[6].node.publicURL}
                  alt={data.media.edges[6].node.name}
                  className={styles.sixthImg}
                />
                <div className={`${styles.description2}`}>
                  <h3>Food is lyf.</h3>
                  <p>
                    When we're not seated in front of our screens, we’re seated
                    in front of food – lots of it.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={{ span: 5 }} className={`${styles.removePaddingRight}`}>
              <div className={styles.seventhEighthImg}>
                <img
                  src={data.media.edges[7].node.publicURL}
                  alt={data.media.edges[7].node.name}
                  className={styles.seventhImg}
                />
                <img
                  src={data.media.edges[8].node.publicURL}
                  alt={data.media.edges[8].node.name}
                  className={styles.eighthImg}
                />
                <div className={`${styles.description3}`}>
                  <h3>Family first</h3>
                  <p>
                    We bond and get together as much as we can. We look out for
                    each other like family.
                  </p>
                </div>
              </div>
            </Col>
          </Row>

          <Row xs={1} className={`${styles.row} ${styles.team}`}>
            <Container fluid>
              <Row className={styles.meetTheTeamLabel}>
                <Col>
                  <h3>Meet the team</h3>
                </Col>
              </Row>
              {paginatedPeople}
              <Row xs={2}>
                <Col md className={styles.prevButton}>
                  <button onClick={prevPeople}>
                    <RightArrow className={styles.arrow} /> PREVIOUS
                  </button>
                </Col>
                <Col md className={`${styles.moreButton} text-right`}>
                  <button onClick={nextPeople}>
                    NEXT <RightArrow className={styles.arrow} />
                  </button>
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
        <div className={styles.spaceBottom}></div>

        <div
          role="button"
          tabIndex={0}
          className={styles.modals}
          onClick={modalClick}
          onKeyDown={() => {}}
        >
          <div className={`${styles.personModal} ${styles.show}`}>
            <Container fluid>
              <Row xs={1} md={2}>
                <Col>
                  <div className={styles.personImage}>
                    <img src="" alt="modal_img" />
                  </div>
                </Col>
                <Col>
                  <div
                    role="button"
                    tabIndex={0}
                    className={styles.closeButton}
                    onClick={handleCloseMemberModal}
                    onKeyDown={() => {}}
                  >
                    <CloseButton />
                  </div>
                  <div className={styles.personDetails}>
                    <h4 id="name" className={styles.name}>
                      Pam
                    </h4>
                    <h5 id="title" className={styles.title}>
                      Engineer
                    </h5>

                    <p id="school" className={styles.school}>
                      UP Diliman <br />
                      BS CoE <br />
                    </p>

                    <div className={styles.tools}>
                      <span id="tools"></span>
                      <RightArrow
                        className={styles.nextToolsArrow}
                        onClick={nextIcons}
                      />
                    </div>

                    <p id="writeup" className={styles.writeUp}>
                      What she says: "The deadline is on X day." <br />
                      What they think: Something bad will happen to you if you
                      don’t finish it before the deadline. <br /> <br />
                      What she says: "That looks good on you." <br />
                      What they think: You look horrible. <br /> <br />
                      What she says: "No." <br />
                      What they think: End of discussion. <br /> <br />
                      Gladys believes that other people see her as a kind,
                      cheerful person who is a friend to all and has a very good
                      sense of humor. Surely, people would not dare think
                      otherwise.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Contact
          bgColorLeft="#0C59AC"
          fontColorLeft="#FDFEFD"
          bgColorRight="#F2F2F2"
          fontColorRight="#0C59AC"
          linkColor="#C3E4F6"
          linkBlue={true}
        />
      </div>
    </Layout>
  );
};

export default About;
