import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Lottie from "react-lottie";
import RightArrow from "../assets/icons/chevron-right.svg";
import ArrowDown from "../lotties/arrow-down-blue";
import styles from "./styles.module.scss";

const downArrow = {
  loop: true,
  autoplay: true,
  animationData: ArrowDown,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const ContactBottomPage = ({
  bgColorLeft,
  bgColorRight,
  fontColorRight,
  fontColorLeft,
  linkColor,
  linkBlue,
}) => (
  <Container fluid>
    <Row xs={1} md={2} className={styles.contactus}>
      <Col
        className={styles.column}
        style={{ background: bgColorLeft, color: fontColorLeft }}
      >
        <div className={styles.details}>
          <div className={styles.sentences}>
            Life is more exciting when you're part of something awesome! <br />
            <br />
            We are currently looking for developers and designers who are hard
            working and crazy talented.
            <div className={styles.link}>
              <Link to="/careers" style={{ color: linkColor ? linkColor : "" }}>
                careers <RightArrow className={styles.arrow} />
              </Link>
            </div>
          </div>
          {/* to add space */}
          <div className={styles.spacing}></div>

          <div className={styles.sentences}>
            Interested in working together on your next project? <br />
            <br />
            Let's build something brilliant together.
            <div className={styles.link}>
              <Link to="/contact" style={{ color: linkColor ? linkColor : "" }}>
                contact us <RightArrow className={styles.arrow} />
              </Link>
            </div>
          </div>
        </div>
      </Col>
      <Col
        className={`${styles.column} text-center`}
        style={{ background: bgColorRight, color: fontColorRight }}
      >
        <div className={styles.details}>
          <p className={styles.sendEmail}>
            Shoot us an email with a link to your portfolio along with a little
            something about yourself.
          </p>
          <Lottie options={downArrow} height="12vh" width="12vh" />
          <a
            href="mailto:hello@mahusai.ph"
            target="_blank"
            className={
              linkBlue ? styles.contactUsLinkBlue : styles.contactUsLink
            }
            rel="noreferrer"
          >
            <p className={styles.email}>
              hello@
              <br />
              mahus
              <br />
              ai.ph
            </p>
          </a>
          <p className={styles.copy}>COPYRIGHT 2020 • MAHUSAI INC.</p>
        </div>
      </Col>
    </Row>
  </Container>
);

ContactBottomPage.propTypes = {
  bgColorLeft: PropTypes.string.isRequired,
  bgColorRight: PropTypes.string.isRequired,
  fontColorRight: PropTypes.string.isRequired,
  fontColorLeft: PropTypes.string.isRequired,
  linkColor: PropTypes.string,
  linkBlue: PropTypes.bool,
};

export default ContactBottomPage;
